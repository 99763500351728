import axios from "axios";
import EventBus from "eventing-bus";
import { web3 } from "../store/web3";
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { setLoader, uploadMetadata, setProfileLink, getKycStatus } from "../store/actions/Auth";
import { React, useState, useEffect } from 'react';
import { makeTokens } from "../store/contract/index";
import { Link, useNavigate } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import modalcloseicon from "../assets/images/close.png";
import Header from '../components/Header';
import Footer from '../components/Footer';

const logo = "/images/logo.svg";
// const daostepinfo = "/images/dao-step-info.png";
const daostepinfo = "/images/circularitydaostakenft-img.png";


const NFTMinting = (props) => {

    const navigate = useNavigate();
    let [priceNFT, setPriceNFT] = useState('');
    let [priceDAONFT, setPriceDAONFT] = useState('');
    let [mintedNFT, setMintedNFT] = useState('');
    let [mintedDAONFT, setMintedDAONFT] = useState('');
    let [saleStart, setSaleStart] = useState(false);
    let [currentTime, setCurrentTime] = useState(0);
    let [DisclaimerModal, setDisclaimerModal] = useState(true);
    let [nftMintedModal, setnftMintedModal] = useState(false);
    const [stepModal, setStepModal] = useState(false);


    useEffect(() => {
        // Update timestamp every second
        const intervalId = setInterval(() => {
            setCurrentTime(Math.floor(Date.now() / 1000));
            if ((Math.floor(Date.now() / 1000)) > 1700157600) {
                setSaleStart(true);
            }
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (props.publicAddress) {
            let publicAddress = props.publicAddress.toLowerCase();
            props.getKycStatus({ publicAddress })
        }
    }, [props.publicAddress]);

    async function loadData() {
        const { CIFINFT, CIFIDAONFT } = await makeTokens();
        let tMinted = await CIFINFT.methods.totalSupply().call();
        setMintedNFT(parseInt(tMinted));
        let tPrice = await CIFINFT.methods.price().call();
        tPrice = web3.utils.fromWei(tPrice.toString(), "ether");
        setPriceNFT(parseInt(tPrice));

        let tDAOMinted = await CIFIDAONFT.methods.totalSupply().call();
        setMintedDAONFT(parseInt(tDAOMinted));
        let tDAOPrice = await CIFIDAONFT.methods.price().call();
        tDAOPrice = web3.utils.fromWei(tDAOPrice.toString(), "ether");
        setPriceDAONFT(parseInt(tDAOPrice));
    }

    async function mintNFT(e) {
        try {
            e.preventDefault();
            const { kycStatus } = props;
            const { CIFINFTAddress, CIFINFT } = await makeTokens();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            if (kycStatus !== "adminApproved") return EventBus.publish("error", `Your KYC has not been submitted or approved yet!`);

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            console.log("*** balanceEther", balanceEther);
            console.log("*** priceNFT", priceNFT);

            if (balanceEther < priceNFT) {
                EventBus.publish("error", `Insufficient balance to mint NFT!`);
                return;
            }

            priceNFT = web3.utils.toWei(priceNFT.toString(), "ether");

            props.setLoader({
                message: "Minting in Progress...",
                status: true,
            });

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: priceNFT,
                    to: CIFINFTAddress,
                    gas: 5000000,
                    data: CIFINFT.methods
                        .mint(from, 1)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    await props.uploadMetadata({ "id": parseInt(mintedNFT + 1) });
                    loadData();
                    setnftMintedModal(true);
                    props.setProfileLink(true);
                    props.setLoader({ status: false });
                    EventBus.publish("success", `Access NFT Minted Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Mint Not Completed...",
                status: false,
            });
            loadData();
            props.setProfileLink(true);
            // EventBus.publish("error", `Mint Failed`);
        }
    };

    async function mintDAONFT(e) {
        try {
            e.preventDefault();
            const { kycStatus } = props;
            const { CIFIDAONFTAddress, CIFIDAONFT, CIFIToken } = await makeTokens();

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            if (kycStatus !== "adminApproved") return EventBus.publish("error", `Your KYC has not been submitted or approved yet!`);

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            priceDAONFT = web3.utils.toWei(priceDAONFT.toString(), "ether");

            let tokenBalance = await CIFIToken.methods.balanceOf(deployer).call();

            if (tokenBalance < priceDAONFT) return EventBus.publish("error", `Insufficient CiFi Token balance to mint NFT`);

            props.setLoader({
                message: "Approving Tokens...",
                status: true,
            });

            await CIFIToken.methods.approve(CIFIDAONFTAddress, priceDAONFT).send({ from: deployer });

            props.setLoader({
                message: "Minting in Progress...",
                status: true,
            });

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: CIFIDAONFTAddress,
                    gas: 5000000,
                    data: CIFIDAONFT.methods
                        .mint(from, 1, priceDAONFT)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    loadData();
                    props.setLoader({ status: false });
                    setStepModal(true);
                    EventBus.publish("success", `CiFi DAO NFT Minted Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.setLoader({
                message: "Mint Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Mint Failed`);
        }
    };

    return (
        <>
            <div className="account sidebar">
                <div className="right">
                    <Header />

                    <div className='container'>
                        {saleStart !== true ?
                            <div className={`form-wrap minting deactivate`}>
                                <h2 className="sale-start">NFT sale will start at <b>10:00:00 AM PST</b></h2>
                                <div className="main-wrap">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="vip-nft-tab" data-bs-toggle="tab" data-bs-target="#vip-nft" type="button" role="tab" aria-controls="vip-nft" aria-selected="true">CIFI VIP NFT</button>
                                        </li>

                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="governor-nft-tab" data-bs-toggle="tab" data-bs-target="#governor-nft" type="button" role="tab" aria-controls="governor-nft" aria-selected="true">CIFI Governor NFT</button>
                                        </li>
                                    </ul>

                                    <Form>
                                        <div class="tab-content" id="myTabContent">
                                            <div class="tab-pane fade show active" id="vip-nft" role="tabpanel" aria-labelledby="vip-nft-tab">
                                                <div className="head">
                                                    <p>Mint this NFT now to access the premium features of Circularity Finance</p>
                                                </div>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalNFTs">
                                                    <Form.Label>Total number of NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={"Unlimited"} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalMintedNFTs">
                                                    <Form.Label>Total Minted NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={mintedNFT} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicpriceperNFT">
                                                    <Form.Label>Price per NFT</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={priceNFT} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                                    <Form.Label>Connected address</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={props.publicAddress} />
                                                </Form.Group>
                                                {props.publicAddress &&
                                                    <Button className="common-btn" onClick={mintNFT}>
                                                        Mint now
                                                    </Button>
                                                }
                                            </div>

                                            <div class="tab-pane fade" id="governor-nft" role="tabpanel" aria-labelledby="governor-nft-tab">
                                                <div className="head">
                                                    <p>Mint this NFT now to access governor features of Circularity Finance</p>
                                                </div>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalNFTs">
                                                    <Form.Label>Total number of NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={"250"} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalMintedNFTs">
                                                    <Form.Label>Total Minted NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={mintedDAONFT} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicpriceperNFT">
                                                    <Form.Label>Price per NFT</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={priceDAONFT.toString() + ` CIFI`} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                                    <Form.Label>Connected address</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={props.publicAddress} />
                                                </Form.Group>
                                                {props.publicAddress &&
                                                    <Button className="common-btn" onClick={mintDAONFT}>
                                                        Mint now
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            :
                            <div className={`form-wrap minting`}>
                                <div className="main-wrap">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="vip-nft-tab" data-bs-toggle="tab" data-bs-target="#vip-nft" type="button" role="tab" aria-controls="vip-nft" aria-selected="true">CIFI VIP NFT</button>
                                        </li>

                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="governor-nft-tab" data-bs-toggle="tab" data-bs-target="#governor-nft" type="button" role="tab" aria-controls="governor-nft" aria-selected="true">CIFI Governor NFT</button>
                                        </li>
                                    </ul>

                                    <Form>
                                        <div class="tab-content" id="myTabContent">
                                            <div class="tab-pane fade show active" id="vip-nft" role="tabpanel" aria-labelledby="vip-nft-tab">
                                                <div className="head">
                                                    <p>Mint this NFT now to access the premium features of Circularity Finance</p>
                                                </div>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalNFTs">
                                                    <Form.Label>Total number of NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={"Unlimited"} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalMintedNFTs">
                                                    <Form.Label>Total Minted NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={mintedNFT} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicpriceperNFT">
                                                    <Form.Label>Price per NFT</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={priceNFT} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                                    <Form.Label>Connected address</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={props.publicAddress} />
                                                </Form.Group>
                                                {props.publicAddress &&
                                                    <Button className="common-btn" onClick={mintNFT}>
                                                        Mint now
                                                    </Button>
                                                }
                                            </div>

                                            <div class="tab-pane fade" id="governor-nft" role="tabpanel" aria-labelledby="governor-nft-tab">
                                                <div className="head">
                                                    <p>Mint this NFT now to access governor features of Circularity Finance</p>
                                                </div>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalNFTs">
                                                    <Form.Label>Total number of NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={"250"} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasictotalMintedNFTs">
                                                    <Form.Label>Total Minted NFTs</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={mintedDAONFT} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicpriceperNFT">
                                                    <Form.Label>Price per NFT</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={priceDAONFT.toString() + ` CIFI`} />
                                                </Form.Group>

                                                <Form.Group className="mb-3 form-group" controlId="formBasicconnectedaddress">
                                                    <Form.Label>Connected address</Form.Label>

                                                    <Form.Control type="text" readOnly disabled value={props.publicAddress} />
                                                </Form.Group>
                                                {props.publicAddress &&
                                                    <Button className="common-btn" onClick={mintDAONFT}>
                                                        Mint now
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Footer />


            <Modal
                open={DisclaimerModal}
                onClose={() => setDisclaimerModal(false)}
                classNames={{
                    modal: `disclaimer-modal`
                }}
                center
            >
                <button className="close">
                    <img
                        src={modalcloseicon}
                        onClick={() => setDisclaimerModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Disclaimer</h2>

                    <p>This NFT is a digital asset that grants access to purchasing NFTs, participating in Private Equity offerings via the CIFI Launchpad.</p> <p> All transactions involve inherent risks, and buyers are advised to proceed with caution and conduct their own due diligence.</p> <p> The NFT owner assumes full responsibility for any associated financial implications.</p>
                </div>
            </Modal>

            <Modal
                open={nftMintedModal}
                onClose={() => setnftMintedModal(false)}
                classNames={{
                    modal: `disclaimer-modal minted-modal`
                }}
                center
            >
                <button className="close">
                    <img
                        src={modalcloseicon}
                        onClick={() => setnftMintedModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>VIP NFT Minted</h2>
                    <div className="img-wrap">
                        <img src="https://groot-asset.s3.us-east-2.amazonaws.com/VIP-CIRCULARITY.gif" />
                    </div>

                </div>
            </Modal>

            <Modal
                open={stepModal}
                onClose={() => setStepModal(false)}
                classNames={{
                    overlay: 'custom-overlay',
                    modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
                }}
                center
            >
                <button className="close">
                    <img
                        src={modalcloseicon}
                        onClick={() => setStepModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="steps-head">
                        <p>Stake your <span>Governor NFT</span> in circularity finance DAO to get rewards</p>
                    </div>


                    <img src={daostepinfo} alt="" />
                </div>
            </Modal>
        </>
    );
};

const mapDispatchToProps = {
    setLoader, uploadMetadata, setProfileLink, getKycStatus
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, xdcPrice, kycStatus } = Auth;
    return { publicAddress, xdcPrice, kycStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(NFTMinting);