/* -- set app title --*/
const AppTitle = 'Circularity VIP Frontend';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['production'];

/* -- set API URLs --*/
const testing = 'http://localhost:4000';
const production = 'https://server.cifiapp.com';
const development = 'https://server.cifiapp.com';
// const development = 'http://localhost:4000';

let SocketUrl;
let env = AppMode[0] || 'development', networkType = '', message = "", explorer = "", networkId = 51;

switch (AppMode[0]) {
  case 'development':
    SocketUrl = development;
    networkId = 51;
    explorer = 'https://explorer.apothem.network'
    message = 'Please switch your network to XDC Testnet';
    networkType = true;
    break;
  case 'production':
    SocketUrl = production;
    networkType = false;
    networkId = 50;
    message = 'Please switch your network to XDC Mainnet';
    explorer = 'https://xdc.blocksscan.io'
    break;
  case 'testing':
    SocketUrl = development;
    networkId = 51;
    explorer = 'https://explorer.apothem.network'
    message = 'Please switch your network to XDC Testnet';
    networkType = true;
    break;
  default:
    SocketUrl = development;
    networkId = 51;
    explorer = 'https://explorer.apothem.network'
    message = 'Please switch your network to XDC Testnet';
    networkType = true;
}
let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, networkType, env, networkId, message, explorer };