import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { useEffect } from 'react';
import Loader from "./components/Loader";
import { createBrowserHistory } from "history";
import { tokenVerify } from "./store/jwtVerify";
import { ToastContainer, toast } from 'react-toastify';
import { logout, setAddress, getXdcPrice } from './store/actions/Auth';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import NFTMinting from './pages/NFTMinting';


const hist = createBrowserHistory();

function App(props) {

  const eventBus = async () => {
    EventBus.on('info', (e) => toast.info(e));
    EventBus.on('error', (e) => toast.error(e));
    EventBus.on('success', (e) => toast.success(e));
    EventBus.on("tokenExpired", () => props.logout());
  }

  useEffect(() => {
    props.getXdcPrice();
    eventBus();
    if (!tokenVerify()) EventBus.on("tokenExpired", () => props.logout());
  },[]);

  useEffect(()=>{
    handleWalletChanges();
  })

  const handleWalletChanges = () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask extension!");
      return;
    };
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", accounts => {
        props.logout();
        EventBus.publish("info", "Account has been changed");
      });

      window.ethereum.on("networkChanged", netId => {
        props.logout();
        EventBus.publish("info", "Network has been changed");
      });
  };
  }
  return (
    <div>
      <Loader />
      <ToastContainer closeOnClick position="bottom-left" theme="colored" />
      <BrowserRouter history={hist}>
        <Routes>
          <Route path="/" element={<NFTMinting />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

const mapDispatchToProps = { logout, setAddress, getXdcPrice };

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth
  return { auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

