import React, { useState, useEffect } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Button } from 'react-bootstrap';
import EventBus from 'eventing-bus';
import { web3 } from "../store/web3";
import { connectMetamask, getNetworkSymbol } from "../store/walletConnect";
import { networkType, networkId } from "../store/config";

import { logout, setAddress, setNetwork, getNonce, login } from '../store/actions/Auth';

const logo = "/logo192.svg";
const profileimg = "/images/profile-img.png";

const Header = (props) => {

  // async function connectWallet(networkId) {
  //   if (typeof window.ethereum === 'undefined') {
  //     EventBus.publish('error', `Please Install Metamask!!!`);
  //     return;
  //   }

  //   web3.eth.net.getId(async (err, netId) => {
  //     if (netId !== networkId) {
  //       if (networkId == 51) EventBus.publish('info', "please select XDC Testnet");
  //       if (networkId == 50) EventBus.publish('info', "please select XDC Mainnet");
  //       return;
  //     }
  //     let x = await web3.currentProvider.chainId;
  //     if (!x) {
  //       EventBus.publish('error', `Please Install Metamask!!!`);
  //       return;
  //     }
  //     let address = (await web3.currentProvider.enable())[0];
  //     if (address) props.setAddress(address);
  //   });
  // };


  async function connectWallet(chainId) {
    if (typeof window.ethereum === 'undefined') {
      EventBus.publish('error', `Please Install Metamask!!!`);
      return;
    }

    web3.eth.net.getId(async (err, netId) => {
      if (netId !== chainId) {
        const isNetworkAdded = await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x32', // Chain ID of XDC Mainnet
              chainName: 'XDC Mainnet',
              rpcUrls: ['https://erpc.xinfin.network'],
              nativeCurrency: {
                name: 'XDC',
                symbol: 'XDC',
                decimals: 18,
              },
              blockExplorerUrls: ['https://xdc.blocksscan.io/'],
            },
          ],
        });

        // Auto-connect if the network is added
        if (isNetworkAdded) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x32' }],
          });
        }
      }
      let x = await web3.currentProvider.chainId;
      if (!x) {
        EventBus.publish('error', `Please Install Metamask!!!`);
        return;
      }
      let address = (await web3.currentProvider.enable())[0];
      if (address) props.setAddress(address);
    });
  };

  return (
    <>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-xl">
            <a class="logo">
              <img src={logo} />

              Circularity Finance
            </a>
            
            <div className='btn-wrap'>
              {/* {
                props.isLink == true &&
                <a class="common-btn" href="https://cifiapp.com/dashboard" target="_blank"> View NFT </a>
              } */}
              <a class="common-btn" href="https://cifiapp.com/dashboard" target="_blank"> Circularity App </a>
            </div>

            <Form>
              {props.publicAddress === '' || props.publicAddress === null ?
                <Button onClick={() => connectWallet(networkId)}> Connect Metamask </Button> :
                <Button onClick={() => connectWallet(networkId)}> {props.publicAddress && props.publicAddress.substring(0, 5) + '...' + props.publicAddress.substring(37, props.publicAddress.length)} </Button>
              }
            </Form>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-bar"></span>
              <span className="navbar-toggler-bar"></span>
              <span className="navbar-toggler-bar"></span>
            </button>
          </nav>
        </div>
      </header>
      <Outlet />
    </>
  )
}


const mapDispatchToProps = { logout, setAddress, setNetwork, getNonce, login };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork, selectDefaultNetwok, isLink } = Auth;
  return { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork, selectDefaultNetwok, isLink }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

