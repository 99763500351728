let { web3 } = require('../web3');
let { env } = require('../config');

let CiFiTokenDATA = require(`./${env}/CiFiToken.json`);
let CiFiNFTDATA = require(`./${env}/CiFiAccessNFT.json`);
let CiFiDAONFTDATA = require(`./${env}/CiFiDAONFT.json`);

let CIFINFTABI = "", CIFINFTAddress = "", CIFINFT = "", 
CIFITokenABI = "", CIFITokenAddress = "", CIFIToken = "",
CIFIDAONFTABI = "", CIFIDAONFTAddress = "", CIFIDAONFT = "";

let makeTokens = async () => {
  return new Promise(async (resolve, reject) => {
    let chain = await web3.eth.getChainId();
    chain = web3.utils.hexToNumber(chain);
    chain = Number(chain);
    if (chain == 50 || chain == 51) {

      /********* CiFi Token *********/
      CIFITokenAddress = CiFiTokenDATA['address']
      CIFITokenABI = CiFiTokenDATA['abi'];
      CIFIToken = new web3.eth.Contract(CIFITokenABI, CIFITokenAddress);

      /********* CiFi NFT *********/
      CIFINFTAddress = CiFiNFTDATA['address']
      CIFINFTABI = CiFiNFTDATA['abi'];
      CIFINFT = new web3.eth.Contract(CIFINFTABI, CIFINFTAddress);

      /********* CiFi DAO NFT *********/
      CIFIDAONFTAddress = CiFiDAONFTDATA['address']
      CIFIDAONFTABI = CiFiDAONFTDATA['abi'];
      CIFIDAONFT = new web3.eth.Contract(CIFIDAONFTABI, CIFIDAONFTAddress);

      return resolve({
        CIFINFTAddress, CIFINFTABI, CIFINFT,
        CIFITokenAddress, CIFITokenABI, CIFIToken,
        CIFIDAONFTAddress, CIFIDAONFTABI, CIFIDAONFT
      })
    }
  })
}

export {
  web3, makeTokens
};
